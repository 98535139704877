import styled from "@emotion/styled";
import * as React from "react";
import { Loader } from "../loader/Loader";

const FullscreenCentered = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const FullscreenLoader = () => {
  return (
    <FullscreenCentered>
      <Loader />
    </FullscreenCentered>
  );
};
