import * as React from "react";

export const SidePanelCard: React.FC<{children?: React.ReactNode}> = props => (
  <div
    style={{
      marginBottom: 6,
      backgroundColor: "#fff",
      borderRadius: 6,
      boxShadow:
        "0 -1px 0 0 rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.2)"
    }}
    {...props}
  />
);

export const StickySidePanelCard: React.FC = props => (
  <div
    style={{
      marginBottom: 6,
      backgroundColor: "#fff",
      borderRadius: 6,
      position: "sticky",
      top: 0,
      boxShadow:
        "0 -1px 0 0 rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.2)"
    }}
    {...props}
  />
);