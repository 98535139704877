import { format, formatDistanceToNow, parseISO } from "date-fns";
import * as React from "react";
import { Ferry } from "../hooks/useFerries";
import { SidePanelCard } from "./sidepanel/SidePanelCard";
import { SidePanelHeader } from "./sidepanel/SidePanelHeader";
import { SidePanelImageGallery } from "./sidepanel/SidePanelImageGallery";
import { SidePanelRow } from "./sidepanel/SidePanelRow";
import { VesselRootObject } from "../hooks/ferry_types";
import { decodeEnDash } from "../lib/util/string";

const formatCoord = (
  num: number,
  suffixIfPositive: string,
  suffixIfNegative: string
): string => {
  const formatter = Intl.NumberFormat("en-GB", { maximumFractionDigits: 3 });
  if (num >= 0) {
    return formatter.format(num) + suffixIfPositive;
  } else {
    return formatter.format(num) + suffixIfNegative;
  }
};

export const FerryPanel = ({
  ferry,
  vessel,
  onClose,
}: {
  ferry: Ferry;
  vessel: VesselRootObject;
  onClose: () => void;
}) => {
  const vesselName = vessel.title.rendered;

  const positionTime = ferry.positionTime + "Z";

  const images = vessel.acf.images
    .filter((v) => v.image)
    .map((v) => v.image as string);

  return (
    <>
      <SidePanelCard>
        <SidePanelHeader title={decodeEnDash(vesselName)} onClose={onClose} />
        {images.length > 0 && <SidePanelImageGallery imageUrls={images} />}
      </SidePanelCard>
      <SidePanelCard>
        <SidePanelRow label="Heading" value={ferry.heading + "°"} />
        <SidePanelRow
          label="Latitude"
          value={formatCoord(ferry.latitude, " N", " S")}
        />
        <SidePanelRow
          label="Longitude"
          value={formatCoord(ferry.longitude, " E", " W")}
        />
        <SidePanelRow
          label="Position time"
          value={
            format(parseISO(positionTime), "yyyy-MM-dd HH:mm") +
            " (" +
            formatDistanceToNow(parseISO(positionTime)) +
            " ago)"
          }
        />
        <SidePanelRow
          label="Speed over ground"
          value={ferry.speedOverGround + " kn"}
        />
        <SidePanelRow label="Length" value={vessel.acf.details.length + " m"} />
        <SidePanelRow label="Width" value={vessel.acf.details.width + " m"} />
        <SidePanelRow label="Built" value={vessel.acf.details.built} />
        <SidePanelRow
          label="Height clearance"
          value={vessel.acf.details.height_clearance + " m"}
        />
        <SidePanelRow
          label="Width clearance"
          value={vessel.acf.details.width_clearance + " m"}
        />
        <SidePanelRow
          label="Freight capacity"
          value={vessel.acf.details.freight_capacity + " lane meters"}
        />
      </SidePanelCard>
    </>
  );
};
