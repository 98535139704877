import * as React from "react";

export const SidePanelList: React.FC<{children?: React.ReactNode}> = props => (
  <ul
    style={{
      margin: "12px 22px 12px 0",
      paddingBottom: "22px",
      fontSize: 14,
      color: "#333"
    }}
    {...props}
  />
);
