import OlFeature from "ol/Feature";
import Stroke from "ol/style/Stroke";
import Style, { StyleFunction } from "ol/style/Style";
import React, { useMemo } from "react";

import { useWPRoutes } from "../hooks/apis";
import { RouteRootObject } from "../hooks/route_types";
import { Feature } from "../map/Feature";
import { Layer } from "../map/Layer";
import { parseLineString } from "../map/utils";
import { routeColor } from "../ui/styleVars";
import { FeatureId } from "./utils";

function transformRouteLine(route: RouteRootObject): OlFeature {
  const geometry = parseLineString(route.acf.points);

  const feature = new OlFeature({ geometry });

  feature.setId(route.id);

  feature.set("sourceType", "routes");

  const styleFn: StyleFunction = (feature, resolution) =>
    new Style({
      stroke: new Stroke({
        color: routeColor,
        lineDash: route.acf.info.dotted_line ? [5, 7] : undefined,
        width: resolution < 4000 ? 4 : 2,
      }),
    });

  feature.set("style", styleFn);

  return feature;
}

interface Props {
  selectedFeature: FeatureId | null;
}

export const FerryRoutes: React.FC<Props> = ({ selectedFeature }) => {
  const routes = useWPRoutes();

  const routesToRender = routes.filter((r) => r.acf && "points" in r.acf);

  const features = useMemo<OlFeature[]>(
    () => routesToRender.map(transformRouteLine),
    [routesToRender]
  );

  return (
    <Layer zIndex={1}>
      {features.map((feature) => (
        <Feature
          key={feature.getId()}
          feature={feature}
          selectedFeature={selectedFeature}
        />
      ))}
    </Layer>
  );
};
