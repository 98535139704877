import * as React from "react";
import { FeatureId, unreachable } from "../map-features/utils";
import { FerryPanel } from "./FerryPanel";
import { PortPanel } from "./PortPanel";
import { RoutePanel } from "./RoutePanel";
import { MapFeature, useMapFeature } from "../hooks/useMapFeature";

const getComponentForFeatureType = (
  resource: MapFeature,
  onClose: () => void
): React.ReactNode => {
  if ("type" in resource) {
    switch (resource.type) {
      case "port":
        return <PortPanel port={resource} onClose={onClose} />;
      case "route":
        return <RoutePanel route={resource} onClose={onClose} />;
      default:
        return unreachable(resource);
    }
  } else {
    return (
      <FerryPanel
        ferry={resource.ferry}
        vessel={resource.vessel}
        onClose={onClose}
      />
    );
  }
};

interface Props {
  featureId: FeatureId;
  onClose: () => void;
}

export const SidePanel: React.FC<Props> = ({ featureId, onClose }) => {
  const feature = useMapFeature(featureId.type, featureId.id);

  if (!feature || featureId.hidePopup) {
    return null;
  }

  const panelContent = getComponentForFeatureType(feature, onClose);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        padding: 8,
        backgroundColor: "#f2f2f2",
        boxShadow:
          "0 -2px 0 0 rgba(0, 0, 0, 0.02), 0 4px 8px 0 rgba(0, 0, 0, 0.3)",
        height: "100vh",
        overflowY: "scroll",
        zIndex: 2
      }}
    >
      {panelContent}
    </div>
  );
};
