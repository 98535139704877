import OlFeature from "ol/Feature";
import CircleStyle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style, { StyleFunction } from "ol/style/Style";
import Text from "ol/style/Text";
import React, { useMemo } from "react";

import { useWPPorts } from "../hooks/apis";
import { PortRootObject } from "../hooks/port_types";
import { Feature } from "../map/Feature";
import { Layer } from "../map/Layer";
import { formatFontProperty, parsePoint } from "../map/utils";
import { fontFamily, routeColor } from "../ui/styleVars";
import { FeatureId } from "./utils";

const positionToOffset: Record<
  "left" | "top" | "right" | "bottom",
  { offsetX: number; offsetY: number }
> = {
  top: { offsetX: 0, offsetY: -20 },
  left: { offsetX: -20, offsetY: 0 },
  bottom: { offsetX: 0, offsetY: 20 },
  right: { offsetX: 20, offsetY: 0 },
};

function getFont(resolution: number) {
  let fontSize = Math.min(29, (50000 / resolution) * 0.7)
  return formatFontProperty(fontSize, fontFamily, '500');
}

function transformRoutePort(port: PortRootObject): OlFeature {
  const geometry = parsePoint(port.acf.points);

  const feature = new OlFeature({ geometry });

  feature.setId(port.id);

  feature.set("sourceType", "ports");

  const { offsetX, offsetY } = positionToOffset[
    port.acf.label_position || "top"
  ];

  const styleFn: StyleFunction = (feature, resolution) =>
    new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({
          color: routeColor,
        }),
      }),
      text:
        resolution < 3500
          ? new Text({
              font: getFont(resolution),
              offsetX,
              offsetY,
              textAlign: port.acf.label_position === "left" ? "right" : "left",
              text: port.id === 590 ? "" : port.title.rendered, //remove one Gothenburg
              fill: new Fill({
                color: "#000",
              }),
              stroke: new Stroke({
                color: "#fff",
                width: 2,
              }),
            })
          : undefined,
    });

  feature.set("style", styleFn);

  return feature;
}

interface Props {
  selectedFeature: FeatureId | null;
}

export const Ports: React.FC<Props> = ({ selectedFeature }) => {
  const ports = useWPPorts();
  const features = useMemo<OlFeature[]>(
    () =>
      ports
        .filter((r) => r.acf && "points" in r.acf && r.acf.points !== "null")
        .map(transformRoutePort),
    [ports]
  );

  return (
    <Layer zIndex={5}>
      {features.map((feature) => (
        <Feature key={feature.getId()} feature={feature} />
      ))}
    </Layer>
  );
};
