import * as React from "react";
import ReactSwipe from "react-swipe";

const SidePanelImage = ({ src }: { src: string }) => {
  return (
    <div
      style={{
        float: "left",
        position: "relative",
        transitionProperty: "transform",
        width: "100%",
        height: 200,
        backgroundImage: `url(${src})`,
        backgroundPosition: "center center",
        backgroundSize: "cover"
      }}
    />
  );
};

interface Props {
  imageUrls: string[];
}

export const SidePanelImageGallery: React.FC<Props> = ({ imageUrls }) => {
  return (
    <ReactSwipe swipeOptions={{ auto: 3000 }}>
      {imageUrls.map(url => (
        <SidePanelImage src={url} key={url} />
      ))}
    </ReactSwipe>
  );
};
