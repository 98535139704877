import * as React from "react";
import { useMemo } from "react";

import { PortRootObject } from "../hooks/port_types";
import { decodeEnDash } from "../lib/util/string";
import { SidePanelCard } from "./sidepanel/SidePanelCard";
import { SidePanelHeader } from "./sidepanel/SidePanelHeader";
import { SidePanelList } from "./sidepanel/SidePanelList";
import { SidePanelRow } from "./sidepanel/SidePanelRow";
import { SidePanelImageGallery } from "./sidepanel/SidePanelImageGallery";
import { useMapFeatures } from "../hooks/useMapFeature";
import { RouteRootObject } from "../hooks/route_types";

export const PortPanel = ({
  port,
  onClose
}: {
  port: PortRootObject;
  onClose: () => void;
}) => {
  console.log(port);

  const routeIds = useMemo<number[]>(() => {
    const arr1 = Array.isArray(port.acf.relation_route_port_destination)
      ? port.acf.relation_route_port_destination
      : [];
    const arr2 = Array.isArray(port.acf.relation_route_port_origin)
      ? port.acf.relation_route_port_origin
      : [];

    return arr1.concat(arr2);
  }, [port]);

  const routes = useMapFeatures("routes", routeIds) as RouteRootObject[];

  const image = port.acf.image ? port.acf.image.sizes.large : undefined;

  const portName = port.title.rendered;
  return (
    <>
      <SidePanelCard>
        <SidePanelHeader title={decodeEnDash(portName)} onClose={onClose} />
        {image && <SidePanelImageGallery imageUrls={[image]} />}
      </SidePanelCard>

      <SidePanelCard>
        <SidePanelRow label="Port benefits" />
        <SidePanelList>
          {port.acf.benefits.map(benefit => (
            <li key={benefit}>{benefit}</li>
          ))}
        </SidePanelList>
      </SidePanelCard>

      <SidePanelCard>
        <SidePanelRow label="Routes for this port" />
        <SidePanelList>
          {routes.map(route => (
            <li key={route.id}>{decodeEnDash(route.title.rendered)}</li>
          ))}
        </SidePanelList>
      </SidePanelCard>
    </>
  );
};
