import { View } from "ol";
import { Coordinate } from "ol/coordinate";
import Feature from "ol/Feature";
import FormatWKT from "ol/format/WKT";
import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
import TileLayer from "ol/layer/Tile";
import Map from "ol/Map";
import { OSM } from "ol/source";
import Style, { StyleFunction } from "ol/style/Style";

import { Location } from "../hooks/apis";
import { MapFeature } from "../hooks/useMapFeature";
import { getMapboxUrl } from "../lib/util/mapbox";
import {
  FeatureId,
  FeatureSourceType,
  unreachable,
} from "../map-features/utils";
import { CenterZoom, Fit, MapView } from "./Map";

export const sourceProjection = "EPSG:4326";
export const targetProjection = "EPSG:3857";

export const transformLocationToPoint = ({
  Latitude,
  Longitude,
}: Location): Point =>
  new Point([Longitude, Latitude]).transform(
    sourceProjection,
    targetProjection
  ) as Point;

const wktParseLine = (routeWkt: string): Coordinate[] => {
  const format = new FormatWKT();

  return (format
    .readFeature(routeWkt)
    .getGeometry() as LineString).getCoordinates();
};

const wktParsePoint = (routeWkt: string): [number, number] => {
  const format = new FormatWKT();

  return (format
    .readFeature(routeWkt)
    .getGeometry() as Point).getCoordinates() as [number, number];
};

export const parsePoint = (pointString: string) =>
  new Point(wktParsePoint(pointString)).transform(
    sourceProjection,
    targetProjection
  ) as Point;

export const parseLineString = (linestring: string) =>
  new LineString(wktParseLine(linestring)).transform(
    sourceProjection,
    targetProjection
  ) as LineString;

export const getPointFromFeature = (
  sourceObject: MapFeature
): [number, number] => {
  if ("type" in sourceObject) {
    switch (sourceObject.type) {
      case "port":
        return parsePoint(sourceObject.acf.points).getCoordinates() as [
          number,
          number
        ];
      case "route":
        return parseLineString(sourceObject.acf.points).getCoordinateAt(
          0.5
        ) as [number, number];
      default:
        return unreachable(sourceObject);
    }
  } else {
    return transformLocationToPoint({
      Longitude: sourceObject.ferry.longitude,
      Latitude: sourceObject.ferry.latitude,
    }).getCoordinates() as [number, number];
  }
};

export const getStyleFn = (
  styleOrStyleFn: Style | StyleFunction
): StyleFunction => (feature, resolution) => {
  if (typeof styleOrStyleFn === "function") {
    return styleOrStyleFn(feature, resolution);
  } else {
    return styleOrStyleFn;
  }
};

export const getInitialView = (view: MapView): View => {
  const mapView = new View({
    minZoom: 4,
    enableRotation: false,
    padding: [60, 60, 60, 60],
  });

  if ("zoom" in view) {
    mapView.setCenter(view.center);
    mapView.setZoom(view.zoom);
  } else {
    mapView.fit(view.fit.getExtent());
  }
  return mapView;
};

export const createMap = (target: HTMLElement, view: View) =>
  new Map({
    target,
    layers: [
      new TileLayer({
        source: new OSM({
          url: getMapboxUrl(),
        }),
      }),
    ],
    view,
  });

export const transformToFeatureId = (f: Feature): FeatureId => ({
  type: f.get("sourceType") as FeatureSourceType,
  id: f.getId() as number,
});

export const animateView = (view: View, newView: CenterZoom | Fit) => {
  if ("zoom" in newView) {
    view.animate({
      center: newView.center,
      zoom: newView.zoom,
    });
  } else {
    view.fit(newView.fit.getExtent(), { duration: 1000 });
  }
};

export const formatFontProperty = (
  fontSize: number,
  fontFamily: string,
  fontWeight = "500"
) => `${fontWeight} ${fontSize}px '${fontFamily}'`;
