import React, { Suspense } from "react";
import { useSelectedFeatureIdState } from "./hooks/useSelectedFeatureIdState";
import { MainMapView } from "./MainMapView";
import { FullscreenLoader } from "./ui/FullscreenLoader";

export const App = () => {
  const [selectedFeatureId, setSelectedFeatureId] = useSelectedFeatureIdState();

  return (
    <Suspense fallback={<FullscreenLoader />}>
      <div
        style={{
          display: "flex",
          flex: 1,
          position: "relative",
          flexDirection: "row",
        }}
      >
        <MainMapView
          selectedFeatureId={selectedFeatureId}
          setSelectedFeatureId={setSelectedFeatureId}
        />
      </div>
    </Suspense>
  );
};
