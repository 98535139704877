import { values } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterMap } from "../lib/util/filterMap";

import { StoreState } from "../store/configureStore";
import { useWPVessels } from "./apis";
import { useFetch } from "./useFetch";
import { useInterval } from "./useInterval";

export const AWS_API_KEY = process.env.REACT_APP_AWS_API_KEY;
const LAMBDA_URL =
  "https://9ujdpmt6d7.execute-api.eu-north-1.amazonaws.com/default/ferries";

if (!AWS_API_KEY) {
  throw new Error("Missing env var: AWS_API_KEY");
}

export interface Ferry {
  positionTime: string;
  heading: number;
  latitude: number;
  name: string;
  type: "ferry";
  speedOverGround: number;
  imo: number;
  longitude: number;
}

type FerryWithoutType = Omit<Ferry, "type">;

const selectNonZeroValues = (value: number) => value;
const joinNumbers = (nums: number[]): string => nums.join(",");

const getFerries = (state: StoreState) => state.ferries;

const useFerriesState = () => {
  const ferries = useSelector(getFerries);
  return useMemo(() => values(ferries) as Ferry[], [ferries]);
};

export const useFerries = (): [Ferry[], number[]] => {
  const ferries = useFerriesState();

  const dispatch = useDispatch();

  const vessels = useWPVessels();
  const imos = useMemo(
    () =>
      filterMap(vessels ?? [], (vessel) =>
        vessel.acf ? Number(vessel.acf.details.imo) : null
      ).filter(selectNonZeroValues),
    [vessels]
  );

  const initialFerries = useFetch(`${LAMBDA_URL}?imos=${joinNumbers(imos)}`, {
    headers: { "x-api-key": AWS_API_KEY },
  }) as FerryWithoutType[];

  const updateFerries = useCallback(() => {
    fetch(`${LAMBDA_URL}?imos=${joinNumbers(imos)}`, {
      headers: { "x-api-key": AWS_API_KEY },
    })
      .then((r) => r.json())
      .then((ferries: FerryWithoutType[]) => {
        dispatch({
          type: "INSERT_MANY",
          resourceType: "ferries",
          data: ferries.map((ferry) => ({ ...ferry, type: "ferry" })),
        });
      });
  }, [dispatch, imos]);

  useEffect(() => {
    dispatch({
      type: "INSERT_MANY",
      resourceType: "ferries",
      data: initialFerries.map((ferry) => ({ ...ferry, type: "ferry" })),
    });
  }, [dispatch, initialFerries]);

  useInterval(updateFerries, 60 * 1000);

  return [ferries, imos];
};
