import React from "react";

export const StenaLineLogo = () => (
  <div
    style={{
      position: "absolute",
      right: 20,
      top: 20,
      background: `url(${process.env.PUBLIC_URL}/sl-logo.svg) no-repeat`,
      backgroundSize: "contain",
      width: 160,
      height: 56
    }}
  />
);
