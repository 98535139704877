import OlFeature from "ol/Feature";
import Circle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Icon from "ol/style/Icon";
import Stroke from "ol/style/Stroke";
import Style, { StyleFunction } from "ol/style/Style";
import Text from "ol/style/Text";
import React, { useMemo } from "react";

import { useFerries } from "../hooks/useFerries";
import { FerryVessel, useMapFeatures } from "../hooks/useMapFeature";
import { Feature } from "../map/Feature";
import { Layer } from "../map/Layer";
import { formatFontProperty, transformLocationToPoint } from "../map/utils";
import { ferryColor, fontFamily, routeColor } from "../ui/styleVars";
import { FeatureId } from "./utils";

const convertToRadian = (num: number) => (num / 180) * Math.PI;

const transformFerryToFeature = (ferryVessel: FerryVessel): OlFeature => {
  const { ferry, vessel } = ferryVessel;

  const feature = new OlFeature({
    geometry: transformLocationToPoint({
      Latitude: ferry.latitude,
      Longitude: ferry.longitude
    })
  });

  feature.setId(ferry.imo);

  feature.set("sourceType", "ferries");

  const selectedIcon = new Icon({
    src: process.env.PUBLIC_URL + "/selected-vessel.svg",
    rotation: convertToRadian(ferry.heading)
  });

  const icon = new Style({
    image: new Icon({
      src: process.env.PUBLIC_URL + "/vessel.svg",
      rotation: convertToRadian(ferry.heading)
    })
  });

  const iconWithName = new Style({
    image: new Icon({
      src: process.env.PUBLIC_URL + "/vessel.svg",
      rotation: convertToRadian(ferry.heading)
    }),
    text: new Text({
      font: formatFontProperty(13, fontFamily, '400'),
      offsetY: 30,
      text: vessel.title.rendered,
      fill: new Fill({
        color: "#000"
      }),
      stroke: new Stroke({
        color: "#fff",
        width: 2
      })
    })
  });

  feature.set(
    "hoverStyle",
    new Style({
      image: selectedIcon,
      text: new Text({
        font: formatFontProperty(13, fontFamily, '400'),
        offsetY: 30,
        text: ferry.name,
        fill: new Fill({
          color: routeColor,
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 2,
        }),
      }),
    })
  );

  const circleIcon = new Style({
    image: new Circle({
      radius: 8,
      fill: new Fill({ color: ferryColor }),
      stroke: new Stroke({
        color: "#fff",
        width: 3
      })
    })
  });

  const styleFn: StyleFunction = (feature, resolution) => {
    if (resolution > 7000) {
      return new Style({});
    } else if (resolution < 400) {
      return iconWithName;
    } else if (resolution < 1400) {
      return icon;
    } else {
      return circleIcon;
    }
  };

  feature.setStyle(styleFn);
  feature.set("style", styleFn);

  return feature;
};

interface Props {
  selectedFeature: FeatureId | null;
}

export const Ferries: React.FC<Props> = ({ selectedFeature }) => {
  // fetch data and suspend
  const [, imos] = useFerries();
  const ferryVessels = useMapFeatures("ferries", imos) as FerryVessel[];

  const features = useMemo(() => ferryVessels.map(transformFerryToFeature), [
    ferryVessels
  ]);

  return (
    <Layer zIndex={10}>
      {features.map(feature => (
        <Feature key={feature.getId()} feature={feature} />
      ))}
    </Layer>
  );
};
