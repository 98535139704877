import OLFeature from "ol/Feature";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import { useContext, useEffect } from "react";
import { FeatureId } from "../map-features/utils";
import { LayerContext } from "./Layer";
import { animatedRouteColor } from "../ui/styleVars";
import RenderEvent from "ol/render/Event";
import { Geometry } from "ol/geom";
import RenderFeature from "ol/render/Feature";

const DURATION_ANIMATION = 800;
let eq = 0; // we go from 0 -> 0.99 -> (-0) -> (-0.99)
// So every other cycle we need to reverse it. This number helps with that.
let reverter = -1; // Controls when cycle changes, and reverts it
let start: number = Date.now();

const styleFn = (resolution: number, event: RenderEvent) => {
  const frameState = event.frameState;
  if (!frameState) {
    return;
  }
  const elapsed = frameState.time - start;

  if (elapsed > DURATION_ANIMATION) {
    reverter = reverter * -1;
    eq = reverter === -1 ? 0 : 1;
    start = Date.now();
  }

  let elapsedRatio =
    eq - (reverter * (elapsed % DURATION_ANIMATION)) / DURATION_ANIMATION;

  if (elapsedRatio <= 0) {
    elapsedRatio = 0;
  } else if (elapsedRatio >= 1) {
    elapsedRatio = 1;
  }

  const width = elapsedRatio + 2;
  const opacity = elapsedRatio + 0.5;
  const minimumWidth = resolution ? 4 : 2;

  return new Style({
    stroke: new Stroke({
      color: animatedRouteColor(opacity),
      width: width + minimumWidth,
    }),
  });
};

export const Feature = ({
  feature,
  selectedFeature,
}: {
  feature: OLFeature;
  selectedFeature?: FeatureId | null;
}) => {
  const layer = useContext(LayerContext)!;
  useEffect(() => {
    if (layer) {
      if (feature.getId() === selectedFeature?.id) {
        start = Date.now();
        layer.on("postrender", (event) => {
          feature.setStyle((f, r) => styleFn(r, event));
        });
      }

      if (!layer.getSource()) {
        console.error("Expected layer and source to be defined", layer); // Breaking changes update for ol lib.
      }
      layer.getSource()?.addFeature(feature);
      const insertedFeature = layer
        .getSource()
        ?.getFeatureById(feature.getId()!);

      if(!insertedFeature || (insertedFeature as RenderFeature[]).length >= 0) {
        console.warn("Unexpected result")
        return () => {}
      } 

      (insertedFeature as OLFeature<Geometry>).setGeometry(feature.getGeometry());

      return () => {
          layer.getSource()?.removeFeature((insertedFeature as OLFeature<Geometry>));
      };
    }
  }, [feature, layer, selectedFeature]);

  return null;
};
