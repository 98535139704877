import React from "react";

export const GDPRButton = () => (
    <button
        id="ot-sdk-btn" className="ot-sdk-show-settings"
        style={{
            position: "absolute",
            left: 20,
            bottom: 20,
            borderColor: '#d0d0d0',
            padding: '.8em 1em',
            fontSize: '0.8em',
            lineHeight: 1.2,
            transition: '0.1s',
            color: '#333',
            backgroundColor: '#fff',
            borderRadius: 5,
        }}
    />
);
