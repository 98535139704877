import * as React from "react";

import { PortRootObject } from "../hooks/port_types";
import { RouteRootObject } from "../hooks/route_types";
import {
  FerryVessel,
  useMapFeature,
  useMapFeatures,
} from "../hooks/useMapFeature";
import { decodeEnDash } from "../lib/util/string";
import { SidePanelCard } from "./sidepanel/SidePanelCard";
import { SidePanelHeader } from "./sidepanel/SidePanelHeader";
import { SidePanelList } from "./sidepanel/SidePanelList";
import { SidePanelRow } from "./sidepanel/SidePanelRow";

export const RoutePanel = ({
  route,
  onClose,
}: {
  route: RouteRootObject;
  onClose: () => void;
}) => {
  const routeName = route.title.rendered;

  const originPort = useMapFeature(
    "ports",
    route.acf.relation_route_port_origin
  ) as PortRootObject | undefined;
  const destinationPort = useMapFeature(
    "ports",
    route.acf.relation_route_port_destination
  ) as PortRootObject | undefined;

  const viaPort = useMapFeature(
    "ports",
    route.acf.relation_route_port_via ?? 0
  ) as PortRootObject | undefined;

  const vessels = useMapFeatures(
    "vessels",
    route.acf.relation_route_vessel ?? []
  ) as Array<FerryVessel>;

  const {
    crossing_time: { hours, minutes },
    depatures,
    departures_frequency,
  } = route.acf.info;

  return (
    <>
      <SidePanelCard>
        <SidePanelHeader title={decodeEnDash(routeName)} onClose={onClose} />
        <SidePanelRow
          label="Frequency"
          value={`${depatures} departures per ${departures_frequency}`}
        />
        <SidePanelRow
          label="Crossing time"
          value={`${hours}h ${minutes ? minutes + " min" : ""}`}
        />
      </SidePanelCard>

      {route.acf.text.benefits && (
        <SidePanelCard>
          <SidePanelRow label="The benefits for this route" />
          <SidePanelList>
            {route.acf.text.benefits.map((benefit) => (
              <li key={benefit}>{benefit}</li>
            ))}
          </SidePanelList>
        </SidePanelCard>
      )}

      <SidePanelCard>
        <SidePanelRow label="Ports" />
        <SidePanelList>
          <li>{decodeEnDash(originPort?.title?.rendered ?? "")}</li>
          {viaPort && <li>{decodeEnDash(viaPort.title.rendered)}</li>}
          <li>{decodeEnDash(destinationPort?.title?.rendered ?? "")}</li>
        </SidePanelList>
      </SidePanelCard>

      {vessels.length > 0 && (
        <SidePanelCard>
          <SidePanelRow label="Ferries" />
          <SidePanelList>
            {vessels.map(({ vessel }) => (
              <li key={vessel.id}>{decodeEnDash(vessel.title.rendered)}</li>
            ))}
          </SidePanelList>
        </SidePanelCard>
      )}
    </>
  );
};
