import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { ErrorBoundary } from "./ErrorBoundary";
import { useDistinctMapFeature } from "./hooks/useMapFeature";
import { Ferries } from "./map-features/Ferries";
import { FerryRoutes } from "./map-features/FerryRoutes";
import { Ports } from "./map-features/Ports";
import { FeatureId } from "./map-features/utils";
import { CenterZoom, MapView, OlMap } from "./map/Map";
import { updateFromPrevView } from "./map/utils/updateFromPreviousView";
import { GDPRButton } from "./ui/GDPRButton";
import { StenaLineLogo } from "./ui/Logo";
import { SidePanel } from "./ui/SidePanel";

const maxWindowLength = Math.max(window.innerWidth, window.innerHeight);

const initialView: CenterZoom = {
  center: [967341.5108293204, 7645273.362518926],
  zoom: maxWindowLength < 1400 ? 9 : 6,
};

const RESET_VIEW_WHEN_INACTIVE = true;

interface Props {
  setSelectedFeatureId: (v: FeatureId | null) => void;
  selectedFeatureId: FeatureId | null;
}

export const MainMapView: React.FC<Props> = ({
  selectedFeatureId,
  setSelectedFeatureId,
}) => {
  const [view, setView] = useState<MapView>(initialView);

  const selectedFeature = useDistinctMapFeature(
    selectedFeatureId ? selectedFeatureId.type : null,
    selectedFeatureId ? selectedFeatureId.id : 0
  );

  const handleClose = () => {
    setSelectedFeatureId(null);
  };

  useEffect(() => {
    if (selectedFeature) {
      setView(updateFromPrevView(selectedFeature, initialView));
    }
  }, [selectedFeature]);

  const resetView = useCallback(
    debounce(() => {
      setView(initialView);
    }, 10 * 60 * 1000),
    []
  );

  const onViewChange = useCallback(
    (view: MapView) => {
      setView(view);
      if (RESET_VIEW_WHEN_INACTIVE) {
        resetView();
      }
    },
    [resetView]
  );

  return (
    <OlMap
      view={view}
      showSidebar={!!selectedFeatureId}
      onViewChange={onViewChange}
      onSelectFeature={setSelectedFeatureId}
    >
      <ErrorBoundary>
        <Ferries selectedFeature={selectedFeatureId} />
      </ErrorBoundary>
      <ErrorBoundary>
        <FerryRoutes selectedFeature={selectedFeatureId} />
      </ErrorBoundary>
      <ErrorBoundary>
        <Ports selectedFeature={selectedFeatureId} />
      </ErrorBoundary>

      {selectedFeatureId && (
        <SidePanel featureId={selectedFeatureId} onClose={handleClose} />
      )}
      <StenaLineLogo />
      <GDPRButton />
    </OlMap>
  );
};
