import * as React from "react";

export const SidePanelHeader = ({
  title,
  onClose,
}: {
  title: string;
  onClose: () => void;
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 22px",
      }}
    >
      <span style={{ fontWeight: 500, fontSize: 20 }}>{title}</span>
      <button
        type="button"
        onClick={onClose}
        style={{
          fontSize: "30px",
          padding: 0,
          margin: 0,
          background: "inherit",
          border: "none",
          textDecoration: "none",
          textAlign: "center",
          display: "inline-block",
          cursor: "pointer",
          WebkitAppearance: "none",
          MozAppearance: "none",
        }}
      >
        &times;
      </button>
    </div>
  );
};
