import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { MapContext } from "./Map";
import { getStyleFn } from "./utils";

interface LayerProps {
  hidden?: boolean;
  zIndex?: number;
  children?: React.ReactNode
}

export const LayerContext = React.createContext<VectorLayer<
  VectorSource
> | null>(null);

const createLayer = (): VectorLayer<VectorSource> =>
  new VectorLayer({
    source: new VectorSource(),
    visible: true,
    updateWhileAnimating: true,
    updateWhileInteracting: true,
    style: (feature, resolution) => {
      const styleOrStyleFn = feature.get("style");
      const featureStyleFn = getStyleFn(styleOrStyleFn);
      return featureStyleFn(feature, resolution);
    },
  });

export const Layer: React.FC<LayerProps> = ({ children, zIndex, hidden }) => {
  const map = useContext(MapContext);
  const [initialized, setInitialized] = useState(false);

  const layerRef = useRef<VectorLayer<VectorSource> | null>(null);

  function getLayer() {
    let layer = layerRef.current;
    if (layer !== null) {
      return layer;
    }
    let newLayer = createLayer();
    layerRef.current = newLayer;
    return newLayer;
  }

  useEffect(() => {
    if (!map) {
      return;
    }

    const currentLayer = getLayer();
    map.addLayer(currentLayer);

    setInitialized(true);
    return () => {
      map.removeLayer(currentLayer);
    };
  }, [map]);

  useEffect(() => {
    if (layerRef.current && zIndex != null) {
      layerRef.current?.setZIndex(zIndex);
    }
  }, [layerRef, zIndex]);

  useEffect(() => {
    if (layerRef.current) {
      layerRef.current.setVisible(!hidden);
    }
  }, [layerRef, hidden]);

  return (
    <LayerContext.Provider value={layerRef.current}>
      {initialized && children}
    </LayerContext.Provider>
  );
};
