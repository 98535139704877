import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FeatureId, FeatureSourceType } from "../map-features/utils";

type UseStateTuple<T> = [T, Dispatch<SetStateAction<T>>];

const getInitialFeatureIdFromUrl = () => {
  const hash = window.location.hash.substring(1).split("=");

  if (hash) {
    const [type, id, hidePopup] = hash;
    return {
      type: type as FeatureSourceType,
      id: parseInt(id, 10),
      hidePopup: hidePopup === "true",
    };
  } else {
    return null;
  }
};

const setSelectedFeatureIdToUrl = (selectedFeatureId: FeatureId | null) => {
  if (selectedFeatureId) {
    const { type, id } = selectedFeatureId;
    if (type && id) {
      window.location.hash = `${type}=${id}`;
    }
  } else {
    window.location.hash = "";
  }
};

export const useSelectedFeatureIdState = (): UseStateTuple<FeatureId | null> => {
  const [selectedFeatureId, setSelectedFeature] = useState<FeatureId | null>(
    () => getInitialFeatureIdFromUrl()
  );

  useEffect(() => {
    setSelectedFeatureIdToUrl(selectedFeatureId);
  }, [selectedFeatureId]);

  return [selectedFeatureId, setSelectedFeature];
};
