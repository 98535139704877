import { MapFeature } from "../../hooks/useMapFeature";
import { CenterZoom, MapView } from "../Map";
import { getPointFromFeature, parseLineString } from "../utils";

export const updateFromPrevView = (
  selectedFeature: MapFeature,
  initialView: CenterZoom
) => (prevView: MapView): MapView => {
  if ("type" in selectedFeature && selectedFeature.type === "route") {
    return { fit: parseLineString(selectedFeature.acf.points) };
  } else {
    const center = getPointFromFeature(selectedFeature);
    return {
      center,
      zoom: Math.max("zoom" in prevView ? prevView.zoom : initialView.zoom, 9)
    };
  }
};
