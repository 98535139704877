import * as React from "react";

export const SidePanelRow = ({
  label,
  value,
}: {
  label: string;
  value?: string;
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "22px 22px 12px",
      gap: "1rem",
    }}
  >
    <span style={{ fontWeight: 500, fontSize: 14, color: "#000" }}>
      {label}
    </span>
    {value && <span style={{ fontSize: 14, color: "#333" }}>{value}</span>}
  </div>
);
