import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { VesselRootObject } from "./ferry_types";
import { PortRootObject } from "./port_types";
import { RouteRootObject } from "./route_types";
import { useFetch } from "./useFetch";

const WP_BASE_URL = "https://stenalinefreight.com/api/wp/v2";

export interface Location {
  Longitude: number;
  Latitude: number;
}

export const useWPVessels = (): VesselRootObject[] => {
  const vessels = useFetch(`${WP_BASE_URL}/vessel?per_page=100`);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "INSERT_MANY", resourceType: "vessels", data: vessels });
  }, [vessels, dispatch]);

  return vessels;
};

export const useWPRoutes = (): RouteRootObject[] => {
  const routes = useFetch(`${WP_BASE_URL}/route?per_page=100`);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "INSERT_MANY", resourceType: "routes", data: routes });
  }, [routes, dispatch]);

  return routes;
};

export const useWPPorts = (): PortRootObject[] => {
  const ports = useFetch(`${WP_BASE_URL}/port?per_page=100`);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "INSERT_MANY", resourceType: "ports", data: ports });
  }, [dispatch, ports]);

  return ports;
};
