import deepEqual from "deep-equal";
import _ from "lodash";
import Feature from "ol/Feature";
import Geometry from "ol/geom/Geometry";
import Select, { SelectEvent } from "ol/interaction/Select";

import Map from "ol/Map";
import MapEvent from "ol/MapEvent";

import "ol/ol.css";
import { StyleFunction } from "ol/style/Style";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useLatest } from "../hooks/useLatest";
import { FeatureId } from "../map-features/utils";
import { animateView, createMap, getInitialView, getStyleFn, transformToFeatureId } from "./utils";

export const MapContext = React.createContext<Map | null>(null);

export interface CenterZoom {
  center: [number, number];
  zoom: number;
}

export interface Fit {
  fit: Geometry;
}

export type MapView = CenterZoom | Fit;

interface MapProps {
  view: MapView;
  showSidebar: boolean;
  onViewChange: (v: MapView) => void;
  onSelectFeature: (v: FeatureId) => void;
  children?: React.ReactNode
}

const styleFn: StyleFunction = (feature, resolution) => {
  const styleOrStyleFn = feature.get("style");
  const featureStyleFn = getStyleFn(styleOrStyleFn);
  return featureStyleFn(feature, resolution);
};

const sortFeaturesByPointType = (features: Feature[]) =>
  _.sortBy(features, f => f.getGeometry()!.getType() !== "Point");

export const OlMap: React.FC<MapProps> = ({
  children,
  view,
  showSidebar,
  onViewChange,
  onSelectFeature
}) => {
  const [initialized, setInitialized] = useState(false);
  const elemRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<Map | null>(null);

  const viewChangeCallback = useLatest((value: MapView) => {
    if (!deepEqual(view, value)) {
      onViewChange(value);
    }
  });

  const featureSelectCallback = useLatest(onSelectFeature);

  useEffect(() => {
    if (elemRef.current) {
      const map = createMap(elemRef.current, getInitialView(view));
      mapRef.current = map;

      map.on("moveend", (evt: MapEvent) => {
        const view1 = evt.map.getView();
        const center = view1.getCenter() as [number, number];
        const zoom = view1.getZoom()!;
        viewChangeCallback.current({ center, zoom });
      });

      const clickSelect = new Select({
        hitTolerance: 10,
        style: styleFn
      });

      map.addInteraction(clickSelect);

      clickSelect.on("select", (event: SelectEvent) => {
        if (event.selected.length > 0) {
          const sourceObjects = sortFeaturesByPointType(event.selected).map<
            FeatureId
          >(transformToFeatureId);

          featureSelectCallback.current(sourceObjects[0]);
        }
      });

      setInitialized(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.updateSize();
    }
  }, [showSidebar]);

  useEffect(() => {
    if (mapRef.current) {
      animateView(mapRef.current.getView(), view);
    }
  }, [view]);

  return (
    <MapContext.Provider value={mapRef.current}>
      <div ref={elemRef} style={{ flex: 1 }} />
      {initialized && children}
    </MapContext.Provider>
  );
};
